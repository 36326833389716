"use client";

import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from "shared/ui/components/snackbar";
import LanguagePopover from "../../../layouts/common/language-popover";
import { useTranslate } from "../../../locales";
import { addTranslations } from "../../../locales/i18n";
import { apiPost } from "../../../utils/api.utils";
import FormProvider from "../../hook-form/form-provider";
import Iconify from "../../iconify";
import { useSettingsContext } from "../context";
import BaseOptions from "./base-option";

// ----------------------------------------------------------------------

addTranslations({
  en: {
    SettingsAppearanceView: {
      language: "Language",
      mode: "Mode",
      contrast: "Contrast",
      reset: "Reset",
      save: "Save Changes",
      updated: "Update success!"
    }
  },
  fr: {
    SettingsAppearanceView: {
      language: "Langue",
      mode: "Mode",
      contrast: "Contraste",
      reset: "Remettre à zero",
      save: "Sauvegarder",
      updated: "Enregistré"
    }
  }
});
export default function SettingsAppearanceView() {
  const settings = useSettingsContext();
  const {
    t
  } = useTranslate();
  const labelStyles = {
    mb: 1.5,
    color: "text.disabled",
    fontWeight: "fontWeightSemiBold"
  };
  const renderLocale = <div>
      <Typography variant="caption" component="div" sx={{
      ...labelStyles
    }}>
        {t("SettingsAppearanceView.language")}
      </Typography>

      <LanguagePopover />
    </div>;
  const renderMode = <div>
      <Typography variant="caption" component="div" sx={{
      ...labelStyles
    }}>
        {t("SettingsAppearanceView.mode")}
      </Typography>

      <BaseOptions value={settings.themeMode} onChange={(newValue: string) => settings.onUpdate("themeMode", newValue)} options={["light", "dark"]} icons={["sun", "moon"]} />
    </div>;
  const renderContrast = <div>
      <Typography variant="caption" component="div" sx={{
      ...labelStyles
    }}>
        {t("SettingsAppearanceView.contrast")}
      </Typography>

      <BaseOptions value={settings.themeContrast} onChange={(newValue: string) => settings.onUpdate("themeContrast", newValue)} options={["default", "bold"]} icons={["contrast", "contrast_bold"]} />
    </div>;

  // const renderDirection = (
  //   <div>
  //     <Typography variant="caption" component="div" sx={{ ...labelStyles }}>
  //       Direction
  //     </Typography>

  //     <BaseOptions
  //       value={settings.themeDirection}
  //       onChange={(newValue: string) => settings.onUpdate("themeDirection", newValue)}
  //       options={["ltr", "rtl"]}
  //       icons={["align_left", "align_right"]}
  //     />
  //   </div>
  // );

  // const renderStretch = (
  //   <div>
  //     <Typography
  //       variant="caption"
  //       component="div"
  //       sx={{
  //         ...labelStyles,
  //         display: "inline-flex",
  //         alignItems: "center",
  //       }}
  //     >
  //       Stretch
  //       <Tooltip title="Only available at large resolutions > 1600px (xl)">
  //         <Iconify icon="eva:info-outline" width={16} sx={{ ml: 0.5 }} />
  //       </Tooltip>
  //     </Typography>

  //     <StretchOptions
  //       value={settings.themeStretch}
  //       onChange={() => settings.onUpdate("themeStretch", !settings.themeStretch)}
  //     />
  //   </div>
  // );

  // const renderPresets = (
  //   <div>
  //     <Typography variant="caption" component="div" sx={{ ...labelStyles }}>
  //       Presets
  //     </Typography>

  //     <PresetsOptions
  //       value={settings.themeColorPresets}
  //       onChange={(newValue: string) => settings.onUpdate("themeColorPresets", newValue)}
  //     />
  //   </div>
  // );

  const {
    enqueueSnackbar
  } = useSnackbar();
  const methods = useForm({
    defaultValues: {}
  });
  const {
    // watch,
    // control,
    handleSubmit,
    formState: {
      isSubmitting
    }
  } = methods;

  // const values = watch();

  const onSubmit = handleSubmit(async () => {
    try {
      const {
        themeStretch,
        themeMode,
        themeDirection,
        themeContrast,
        themeLayout,
        themeColorPresets,
        lang,
        ..._rest
      } = settings;
      await apiPost("/user/settings", {
        body: {
          theme_settings: {
            themeStretch,
            themeMode,
            themeDirection,
            themeContrast,
            themeLayout,
            themeColorPresets,
            lang
          }
        }
      });
      enqueueSnackbar(t("SettingsAppearanceView.updated"));
    } catch (error) {
      console.error(error);
    }
  });
  return <FormProvider methods={methods} onSubmit={onSubmit} data-sentry-element="FormProvider" data-sentry-component="SettingsAppearanceView" data-sentry-source-file="settings-view.tsx">
      <Stack data-sentry-element="Stack" data-sentry-source-file="settings-view.tsx">
        <Stack spacing={3} sx={{
        p: 3
      }} data-sentry-element="Stack" data-sentry-source-file="settings-view.tsx">
          {renderLocale}
          {renderMode}

          {renderContrast}

          {/* {renderDirection} */}

          {/* {renderStretch} */}

          {/* {renderPresets} */}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{
        py: 2,
        pr: 1,
        pl: 2.5
      }} data-sentry-element="Stack" data-sentry-source-file="settings-view.tsx">
          <Box sx={{
          flexGrow: 1
        }} data-sentry-element="Box" data-sentry-source-file="settings-view.tsx" />

          <Stack direction="row" spacing={2} data-sentry-element="Stack" data-sentry-source-file="settings-view.tsx">
            <Button variant="outlined" color="inherit" onClick={settings.onReset} sx={settings.canReset ? {
            borderColor: "error.main",
            color: "error.main"
          } : {}} data-sentry-element="Button" data-sentry-source-file="settings-view.tsx">
              <Iconify icon="solar:restart-bold" sx={{
              mr: 0.5,
              mb: 0.2
            }} data-sentry-element="Iconify" data-sentry-source-file="settings-view.tsx" />
              {t("SettingsAppearanceView.reset")}
            </Button>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting} sx={{
            ml: "auto"
          }} data-sentry-element="LoadingButton" data-sentry-source-file="settings-view.tsx">
              {t("SettingsAppearanceView.save")}
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
    </FormProvider>;
}