import type { BoxProps } from "@mui/material/Box";
import Box from "@mui/material/Box";
import React, { forwardRef } from "react";

// ----------------------------------------------------------------------

export type SvgColorProps = BoxProps & {
  src: string;
};

// eslint-disable-next-line react/display-name
const SvgColor = forwardRef<HTMLSpanElement, SvgColorProps>(({
  src,
  sx,
  ...other
}, ref) => <Box component="span" className="svg-color" ref={ref} sx={{
  width: 24,
  height: 24,
  display: "inline-block",
  bgcolor: "currentColor",
  mask: `url(${src}) no-repeat center / contain`,
  WebkitMask: `url(${src}) no-repeat center / contain`,
  ...sx
}} {...other} />);
export default SvgColor;