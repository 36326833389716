import { menuItemClasses } from "@mui/material/MenuItem";
import type { PopoverOrigin } from "@mui/material/Popover";
import Popover from "@mui/material/Popover";
import React from "react";
import { StyledArrow } from "./styles";
import type { MenuPopoverProps } from "./types";
import { getPosition } from "./utils";

// ----------------------------------------------------------------------

export default function CustomPopover({
  open,
  children,
  arrow = "top-right",
  hiddenArrow,
  sx,
  ...other
}: MenuPopoverProps) {
  const {
    style,
    anchorOrigin,
    transformOrigin
  } = getPosition(arrow);
  return <Popover open={Boolean(open)} anchorEl={open} anchorOrigin={anchorOrigin as PopoverOrigin} transformOrigin={transformOrigin as PopoverOrigin} slotProps={{
    paper: {
      sx: {
        width: "auto",
        overflow: "inherit",
        ...style,
        [`& .${menuItemClasses.root}`]: {
          "& svg": {
            mr: 2,
            flexShrink: 0
          }
        },
        ...sx
      }
    }
  }} {...other} data-sentry-element="Popover" data-sentry-component="CustomPopover" data-sentry-source-file="custom-popover.tsx">
      {!hiddenArrow && <StyledArrow arrow={arrow} />}

      {children}
    </Popover>;
}