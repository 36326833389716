import { Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, { useCallback, useState } from "react";
import CustomPopover, { usePopover } from "../../components/custom-popover";
import Iconify from "../../components/iconify";
import { useLocales, useTranslate } from "../../locales";

// ----------------------------------------------------------------------

export const allLangs = [{
  label: "English",
  value: "en",
  icon: "flagpack:gb-nir"
}, {
  label: "French",
  value: "fr",
  icon: "flagpack:fr"
}];
export default function LanguagePopover() {
  const popover = usePopover();
  const {
    currentLang
  } = useLocales();
  const {
    onChangeLang
  } = useTranslate();
  const [currentLangInternal, setCurrentLangInternal] = useState(currentLang);
  const handleChangeLang = useCallback(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (newValue: any) => {
    onChangeLang(newValue.value);
    setCurrentLangInternal(newValue);
    popover.onClose();
  }, [popover]);
  return <>
      <Button variant="outlined" onClick={popover.onOpen} sx={{
      ...(popover.open && {
        bgcolor: "action.selected"
      })
    }} startIcon={<Iconify icon={currentLangInternal.icon} sx={{
      borderRadius: 0.65,
      width: 28
    }} />} data-sentry-element="Button" data-sentry-source-file="language-popover.tsx">
        {currentLangInternal.label}
      </Button>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{
      width: 160
    }} data-sentry-element="CustomPopover" data-sentry-source-file="language-popover.tsx">
        {allLangs.map(option => <MenuItem key={option.value} selected={option.value === currentLangInternal.value} onClick={() => handleChangeLang(option)}>
            <Iconify icon={option.icon} sx={{
          borderRadius: 0.65,
          width: 28
        }} />

            {option.label}
          </MenuItem>)}
      </CustomPopover>
    </>;
}